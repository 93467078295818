import React from "react";
import { Countdown } from '../components/countdown'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

export class Home extends React.Component {
    constructor() {
        super();
    }

    componentDidMount() {
        console.log('Home');
    }

    render() {
        return (
            <div className="App">
                <Header />

                <section className="Timeleft-section" >
                    <Countdown />
                </section>

                <Footer />
          </div>
        );
    }
}