import React from 'react';
import './footer.css';


export class Footer extends React.Component {
    constructor() {
      super();
      this.state = {}
    }
  
    render() {

        return(
            <div className="footer-container">
                <span className="footer-label">Powered by Kristoffer Svensson, KoffeCode. Background image: VisitKarlshamn</span>
            </div>
      );
    }
  }