import React from "react";
import '../styles/Login.css'

export class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            username: "",
            password: ""
        }
    }

    componentDidMount() {
        console.log('Login');
    }

    initLogin() {
        console.log(this.state);
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-container">
                    <div class="login-title">
                        <h1>Admin</h1>
                    </div>
                    <div class="login-username input-container">
                        <input type="text" placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                    </div>
                    <div class="login-password input-container">
                    <input type="password" placeholder="Password" value={this.state.password} />
                    </div>
                    <div class="login-button">
                        <button onClick={this.initLogin}>
                            Login
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}