import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { Home } from '../views/Home';
import { Login } from '../views/Login'
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <AdminRoute path="/secure/admin">
          <div>
            Admin home
          </div>
        </AdminRoute>
        <Route path="/secure/login">
          <Login />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

function AdminRoute({ children, ...rest }) {
  let auth = true;
  return (
    <Route {...rest} render={({ location }) => 
      auth ? ( children ) :
      (<Redirect to={{pathname: "/secure/login", state: { from: location }}} />)
    }>
    </Route>
  );
}

export default App;
