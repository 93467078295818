import React from 'react';
import './header.css';


export class Header extends React.Component {
    constructor() {
      super();
      this.state = {}
    }
  
    render() {

        return(
            <div className="header-container">
                <h1 className="header-label">TBV 729</h1>
            </div>
      );
    }
  }