export const TbvDates = {
    "2021": {
        "first": "May 21",
        "last": "Nov 12"
    },
    "2022": {
        "first": "May 12",
        "last": "Nov 18"
    },
    "2023": {
        "first": "May 19",
        "last": "Nov 17"
    },
    "2024": {
        "first": "May 17",
        "last": "Nov 15"
    },
    "2025": {
        "first": "May 16",
        "last": "Nov 14"
    },
    "2026": {
        "first": "May 15",
        "last": "Nov 13"
    },
    "2027": {
        "first": "May 21",
        "last": "Nov 19"
    },
    "2028": {
        "first": "May 19",
        "last": "Nov 17"
    },
    "2029": {
        "first": "May 18",
        "last": "Nov 16"
    },
    "2030": {
        "first": "May 17",
        "last": "Nov 15"
    },
    "2031": {
        "first": "May 16",
        "last": "Nov 14"
    },
    "2032": {
        "first": "May 14",
        "last": "Nov 12"
    },
    "2033": {
        "first": "May 20",
        "last": "Nov 18"
    },
}