import React from 'react';
import './countdown.css';
import {TbvDates} from './hardCodedDates';

export class Countdown extends React.Component {
    constructor() {
        super();
        
        this.calculateDate();
        
        this.state = { time: {}, partyTime: false };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.timeBetweenDates = this.timeBetweenDates.bind(this)
    }
  
    componentDidMount() {
        this.startTimer()
    }

    componentWillUnmount() {
        this.setState({ timer: 0 })
    }

    calculateDate() {
        const today = new Date();
        this.firstDateOfTheYear = new Date(`${TbvDates[today.getFullYear()].first}, ${today.getFullYear()} 17:00:00`);
        this.lastDateOfTheYear = new Date(`${TbvDates[today.getFullYear()].last}, ${today.getFullYear()} 17:00:00`);

        if (this.firstDateOfTheYear.getTime() - today.getTime() > 0) {
            this.nextDateToMeet = this.firstDateOfTheYear;
        } else {
            if (this.lastDateOfTheYear.getTime() - today.getTime() > 0) {
                this.nextDateToMeet = this.lastDateOfTheYear;
            } else {
                this.nextDateToMeet = new Date(`${TbvDates[today.getFullYear() + 1].first}, ${today.getFullYear() + 1} 17:00:00`);
            }
        }

        const amountOfDays = this.nextDateToMeet.getDay() === 4 ? 4 : 3;
        this.daysInMilliSeconds = 1000 * 60 * 60 * 24 * amountOfDays; 
    }

    startTimer() {
        if (this.timer === 0) {
            this.timer = setInterval(() => {
                this.timeBetweenDates();
            }, 1000);
        }
    }

    timeBetweenDates() {
        let now = new Date();
        let difference = this.nextDateToMeet.getTime() - now.getTime();

        if (difference <= 0 && difference > -this.daysInMilliSeconds) {
            this.setState({ partyTime: true })
        } else {
            const DAYS_CALCULATION = (1000 * 60 * 60 * 24)
            const HOURS_CALCULATION = (1000 * 60 * 60)
            const MINUTE_CALCULATION = (1000 * 60)

            const days = Math.floor(difference / DAYS_CALCULATION);
            const hours = Math.floor((difference % DAYS_CALCULATION) / HOURS_CALCULATION);
            const minutes = Math.floor((difference % HOURS_CALCULATION) / MINUTE_CALCULATION);
            const seconds = Math.floor((difference % MINUTE_CALCULATION) / 1000);
            
            const obj = {
                "d": days,
                "h": hours,
                "m": minutes,
                "s": seconds
            };

            this.setState({ time: obj, partyTime: false })
        }
    }
  
    render() {
        const { partyTime, time } = this.state
        
        return(
        <div className="countdown-left-container">
            {!partyTime ?
                <> 
                    <div className="countdown-left-content">
                        <span className="countdown-left-counter">{time.d ? time.d : "..."}</span>
                        <span className="countdown-left-label">Days</span>
                    </div>
                    <div className="countdown-left-content border-side">
                        <span className="countdown-left-counter">{time.h ? time.h : "..."}</span>
                        <span className="countdown-left-label">Hours</span>
                    </div>
                    <div className="countdown-left-content border-side">
                        <span className="countdown-left-counter">{time.m ? time.m : "..."}</span>
                        <span className="countdown-left-label">Minutes</span>
                    </div>
                    <div className="countdown-left-content">
                        <span className="countdown-left-counter">{time.s ? time.s : "..."}</span>
                        <span className="countdown-left-label">Seconds</span>
                    </div>    
                </>
            :
                <>
                    <div className="countdown-left-content">
                        <h1>PARTYTIME!!!</h1>
                    </div>
                </>
            }
        </div>
      );
    }
  }